.createDeckPopout {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../background-images/createDeckBackGround.jpg);
  background-size: cover;
  z-index: 100;
  width: 800px;
  height: 800px;
}

.createNewDeckButton {
  position: relative;
  top: 61%;
  right: -41%;
}

.importNewDeckButton {
  position: relative;
  top: 62%;
  right: -43%;
}

.changeDeckNameButton {
  position: relative;
  top: 62%;
  right: -39%;
}

.deckCreateWarning {
  position: absolute;
  right: 9%;
}

#deckSaveInput {
  position: relative;
  top: 50%;
  left: 26%;
  width: 400px;
  font-size: 25px;
  margin: 5px;
  padding: 5px 10px;
  transition: ease-in-out 0.3s;
  border: 0px;
  border-radius: 10px;
}

.currDeckTitle,
.loadDecksTitle {
  margin-top: 0px;
  display: flex;
  width: 450px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  justify-content: space-around;
  cursor: pointer;
}

.createDeckCancel {
  position: relative;
  top: 66%;
  right: -24%;
}

.importDeckCancel {
  position: relative;
  top: 67%;
  right: -29%;
}

.changeDeckNameCancel {
  position: relative;
  top: 67%;
  right: -21%;
}

.rightArrowIcon {
  position: relative;
  right: -28px;
  top: 4px;
}

.deckLeftArrowIcon {
  transform: rotateY(180deg);
  position: relative;
  left: -29px;
  top: 4px;
}

.deckSideboardSeperator {
  position: relative;
  top: -4px;
}

.deckSelectorSpan {
  position: relative;
  right: -19px;
}

.sideboardSelectorSpan {
  position: relative;
  left: -17px;
}

.invalidDeck {
  position: relative;
  color: white;
}
.invalidDeckBefore > div::before {
  content: "";
  position: absolute;
  top: 7px;
  left: -6px;
  cursor: auto !important;
  right: 10px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  opacity: 0.8;
  width: 151px;
}

.invalidDeck::after {
  font-size: x-large;
  content: "INVALID";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.deckBuilderInputBar {
  position: relative;
  width: 300px;
  margin-top: 0px;
  margin-left: 1.5%;
  margin-right: 5px;
  top: 60%;
  left: 32%;
}

.deckBuilderInputBar input {
  position: relative;
  width: 100%;
  padding: 10px 10px 13px;
  background: transparent;
  border: none;
  outline: none;
  color: #23242a;
  font-size: 30px;
  letter-spacing: 0.05em;
  z-index: 10;
  top: 7px;
}

.deckBuilderInputBar span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 2em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.deckBuilderInputBar input:valid ~ span,
.deckBuilderInputBar input:focus ~ span {
  color: #9a6529;
  transform: translateX(-10px) translateY(-30px);
  font-size: 0.75em;
}

.deckBuilderInputBar i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #9a6529;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.deckBuilderInputBar input:valid ~ i,
.deckBuilderInputBar input:focus ~ i {
  height: 44px;
}

.deckBuilderInputBar input:-webkit-autofill,
.deckBuilderInputBar input:-webkit-autofill:hover,
.deckBuilderInputBar input:-webkit-autofill:focus,
.deckBuilderInputBar input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #9a6529 inset !important;
  height: 44px;
}

.deckLimitExceededIcon {
  color: white;
  display: inline-block;
  position: absolute;
  right: 35px;
}