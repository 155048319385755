/* SIGNUP LOGIN FORM */
.formPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 50px;
}

.box {
  position: relative;
  width: 370px;
  height: 500px;
  background: #1c1c1c;
  border-radius: 50px 5px;
  overflow: hidden;
}

.box::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 370px;
  height: 500px;
  background: linear-gradient(60deg, transparent, #9a6529, #9a6529);
  transform-origin: bottom right;
  animation: animate 36s linear infinite;
}

.box::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 370px;
  height: 500px;
  background: linear-gradient(60deg, transparent, #9a6529, #9a6529);
  transform-origin: bottom right;
  animation: animate 36s linear infinite;
  animation-delay: -18s;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.box form {
  position: absolute;
  inset: 2px;
  border-radius: 50px 5px;
  background: #28292d;
  z-index: 10;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
}

.box h2 {
  color: #9a6529;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.inputBox {
  position: relative;
  width: 300px;
  margin-top: 35px;
}

.inputBox input {
  position: relative;
  width: 100%;
  padding: 10px 10px 13px;
  background: transparent;
  border: none;
  outline: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  z-index: 10;
}

input[type="submit"] {
  font-size: 20px;
  border: none;
  outline: none;
  background: #9a6529;
  padding: 5px;
  margin-top: 40px;
  border-radius: 90px;
  font-weight: 600;
  cursor: pointer;
}

input[type="submit"]:active {
  background: linear-gradient(90deg, #ffb663, #ff8902);
  opacity: 0.8;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
  color: #9a6529;
  transform: translateX(-10px) translateY(-30px);
  font-size: 0.75em;
}

.inputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #9a6529;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputBox input:valid ~ i,
.inputBox input:focus ~ i {
  height: 44px;
}

.links {
  display: flex;
  justify-content: space-between;
}

.links a {
  margin: 25px;
  font-size: 1em;
  color: #8f8f8f;
  text-decoration: none;
}

.links a,
.links a:hover:nth-child(2) {
  color: #9a6529;
}

.links a:hover:nth-child(2):hover {
  text-decoration: underline 2px;
  text-underline-offset: 5px;
}

.textInformationContainer {
  width: 100%;
  font-size: 35px;
  text-decoration: none;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#passwordColorFix {
  color: #8f8f8f;
}

.inputBox input:-webkit-autofill,
.inputBox input:-webkit-autofill:hover,
.inputBox input:-webkit-autofill:focus,
.inputBox input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #9a6529 inset !important;
  height: 44px;
}

.boxSignUp {
  position: relative;
  width: 370px;
  height: 550px;
  background: #1c1c1c;
  border-radius: 50px 5px;
  overflow: hidden;
}

.boxSignUp::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 370px;
  height: 550px;
  background: linear-gradient(60deg, transparent, #9a6529, #9a6529);
  transform-origin: bottom right;
  animation: animate 36s linear infinite;
}

.boxSignUp::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 370px;
  height: 550px;
  background: linear-gradient(60deg, transparent, #9a6529, #9a6529);
  transform-origin: bottom right;
  animation: animate 36s linear infinite;
  animation-delay: -18s;
}

.boxSignUp form {
  position: absolute;
  inset: 2px;
  border-radius: 50px 5px;
  background: #28292d;
  z-index: 10;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
}

.boxSignUp h2 {
  color: #9a6529;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.boxSignUp .inputBox {
  position: relative;
  width: 300px;
  margin-top: 35px;
}

.boxSignUp .inputBox input {
  position: relative;
  width: 100%;
  padding: 10px 10px 13px;
  background: transparent;
  border: none;
  outline: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  z-index: 10;
}

.boxSignUp input[type="submit"] {
  font-size: 20px;
  border: none;
  outline: none;
  background: #9a6529;
  padding: 5px;
  margin-top: 40px;
  border-radius: 90px;
  font-weight: 600;
  cursor: pointer;
}

.boxSignUp input[type="submit"]:active {
  background: linear-gradient(90deg, #ffb663, #ff8902);
  opacity: 0.8;
}

.boxSignUp .inputBox span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.boxSignUp .inputBox input:valid ~ span,
.boxSignUp .inputBox input:focus ~ span {
  color: #9a6529;
  transform: translateX(-10px) translateY(-30px);
  font-size: 0.75em;
}

.boxSignUp .inputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #9a6529;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.boxSignUp .inputBox input:valid ~ i,
.boxSignUp .inputBox input:focus ~ i {
  height: 44px;
}

.boxSignUp .textInformationContainer {
  width: 100%;
  font-size: 35px;
  text-decoration: none;
}

.boxSignUp .inputBox input:-webkit-autofill,
.boxSignUp .inputBox input:-webkit-autofill:hover,
.boxSignUp .inputBox input:-webkit-autofill:focus,
.boxSignUp .inputBox input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #9a6529 inset !important;
  height: 44px;
}
