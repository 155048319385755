/* <===================== hidden keyword popups =====================> */

#hideDisplay,
#hideDisplayOpponent {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 150px;
  width: 350px;
  height: 200px;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid black;
  text-align: center;
  background: #00000092;
  font-size: 16px;
  z-index: 100;
}

#hideAbilityDisplay {
  position: absolute;
  top: -151px;
  right: -2px;
  opacity: 0;
  height: 0px;
  width: 0px;
  background: none;
  z-index: 100;
  background-image: none;
  transition: height 0s ease-in, opacity 0s ease-in; /* Only ease-in for opacity */
  transition-duration: 0s; /* Duration for hover effect */
}

.spanReplacement:hover #hideAbilityDisplay {
  height: 282px;
  width: 220px;
  opacity: 1;
  background-image: url(/src/background-images/cardbg1.jpg);
  transition: height 1.5s ease-in, opacity 1.5s ease-in; /* Only ease-in for opacity */
}

.hideCard {
  display: none;
}

.currentDeckCard .hideCard {
  display: none;
}

.currentDeckCard:hover .hideCard {
  display: flex;
  position: absolute;
  font-size: 14px;
  left: -225px;
  top: -300px;
  z-index: 100;
}

.hideCardFromView {
  visibility: hidden;
  display: flex;
  position: absolute;
  transform: scale(0.8);
  left: 195px;
  top: -184px;
  z-index: 40;
  opacity: 0;
  transition: opacity .5s ease, visibility .5s ease;
}

.spanCardReplacement {
  text-decoration: underline;
}

.spanCardReplacement:hover .hideCardFromView, .hideCardFromView:hover {
  opacity: 1;
  visibility: visible;
}

.material-symbols-outlined:hover #hideDisplay {
  display: flex;
  white-space: pre-line;
  font-family: "Mali", cursive, sans-serif;
  font-weight: 600;
  color: white;
}

.spanReplacement:hover #hideDisplay {
  display: flex;
}

.giveGreen {
  color: green;
  margin: 0px 8px 0px 4px;
}

.attackAllButton:hover #hideDisplay {
  display: flex;
  color: white;
}

.spanReplacement {
  text-decoration: underline;
}

.saveDeckButton:hover #hideDisplay {
  display: flex;
  top: 50px;
}

/* <===================== hidden keyword popups =====================> */
