/* ───────────────── STORE WRAPPER ───────────────── */
.store-wrapper {
    background-color: rgba(0, 0, 0, 0.85);
    position: relative;
    min-height: 100vh;
    color: #f1f1f1;
    padding-bottom: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  
  /* ───────────────── STORE BANNER ───────────────── */
  .store-banner {
    /* Slight translucent layer on top of the background overlay */
    background-color: rgba(0, 0, 0, 0.4);
    padding: 50px 20px;
    text-align: center;
    margin-bottom: 0;
  }
  
  .banner-title {
    font-size: 2.5rem;
    margin: 0 0 10px;
  }
  
  .banner-subtitle {
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .user-points-info {
    margin-top: 10px;
    font-size: 1.2rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  /* 
     Center the store points display, ensure "Your Points:" is white,
     and align the icon + text nicely.
  */
  .pointDisplay.storePointsOnPage {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
    margin-right: 0;
  }
  
  /* If needed, ensure the strong tag also inherits white color */
  .pointDisplay.storePointsOnPage strong {
    color: inherit;
  }
  
  /* ───────────────── TAB NAVIGATION ───────────────── */
  .tabs-nav {
    background-color: #2b2b2b;
    padding: 10px 20px;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
  }
  
  .tabs-list {
    list-style-type: none;
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  
  .tab-item {
    position: relative;
    cursor: pointer;
    padding: 8px 0;
    font-weight: 500;
    transition: color 0.2s ease;
  }
  
  .tab-item:hover {
    color: #ffffffcc;
  }
  
  /* Active tab uses #9a6529 for the bottom border */
  .tab-item.active {
    color: #fff;
    border-bottom: 3px solid #9a6529;
  }
  
  /* ───────────────── MAIN STORE CONTENT ───────────────── */
  .store-content {
    max-width: 95%;
    margin: 30px auto 0 auto;
    padding: 0 20px;
  }
  
  /* Area for the “Buy Points” button near the top of the content */
  .buy-points-area {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  /* Primary CTA button color is #9a6529, removing any blues */
  .cta-button {
    background-color: #9a6529;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .cta-button:hover {
    background-color: #7e5224;
  }
  
  /* ───────────────── TAB CONTENT BOXES ───────────────── */
  .cards-tab-content,
  .card-backs-tab-content {
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .tab-heading {
    font-size: 1.6rem;
    margin-bottom: 10px;
    border-bottom: 1px solid #404040;
    padding-bottom: 8px;
  }
  
  .tab-description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #d3d3d3;
  }
  
  /* ───────────────── GRID LAYOUT FOR CARD BACKS ───────────────── */
  .card-back-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 20px;
  }
  
  .card-back-tile {
    background-color: #1e1e1e;
    border-radius: 6px;
    padding: 16px;
    text-align: center;
    position: relative;
    transition: transform 0.2s ease;
  }
  
  .card-back-tile:hover {
    transform: scale(1.02);
  }
  
  .card-back-image {
    position: relative;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-evenly;
  }
  
  .owned-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(70, 70, 70, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: #fff;
    border-radius: 6px;
  }
  
  .point-cost-button {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    background-color: #444444;
    border-radius: 4px;
    padding: 6px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .point-cost-button:hover {
    background-color: #666666;
  }
  
  .point-img-icon {
    width: 20px;
    height: 20px;
  }
  
  .cost-text {
    font-size: 0.9rem;
    color: #f1f1f1;
  }
  
  /* ───────────────── MODAL FOR BUYING POINTS ───────────────── */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(28, 28, 28, 0.8);
    backdrop-filter: blur(3px);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  
    /* 
      If the modal content exceeds the viewport, user can scroll 
      instead of pushing the modal off-screen.
    */
    overflow-y: auto;
  }
  
  .modal-content {
    background-color: #2b2b2b;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    position: relative;
    text-align: center;
  
    /* Constrain the modal’s height so that it is scrollable if too tall */
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .close-modal-btn {
    background: none;
    border: none;
    font-size: 1.6rem;
    color: #ffffffcc;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: color 0.2s ease;
  }
  
  .close-modal-btn:hover {
    color: #ffffff;
  }
  
  .modal-content h3 {
    margin: 0 0 10px;
    font-size: 1.8rem;
  }
  
  .modal-content p {
    color: #d3d3d3;
    margin-bottom: 20px;
  }
  
  .stripe-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  /* ───────────────── RESPONSIVE TWEAKS ───────────────── */
  @media (max-width: 768px) {
    .banner-title {
      font-size: 2rem;
    }
  
    .tabs-list {
      gap: 1rem;
    }
  
    .card-back-grid {
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }
  
    .tab-heading {
      font-size: 1.4rem;
    }
  }