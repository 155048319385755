/* ───────────────── OVERALL WRAPPER (Similar to Store) ───────────────── */
.match-history-wrapper {
    background-color: rgba(0, 0, 0, 0.85);
    position: relative;
    min-height: 100vh;
    color: #f1f1f1;
    padding-bottom: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  
  /* ───────────────── BANNER AREA ───────────────── */
  .match-history-banner {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 50px 20px;
    text-align: center;
    margin-bottom: 0;
  }
  
  .banner-title {
    font-size: 2.5rem;
    margin: 0 0 10px;
  }
  
  .banner-subtitle {
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  /* ───────────────── MAIN CONTENT SECTION ───────────────── */
  .match-history-content {
    max-width: 95%;
    margin: 30px auto 0 auto;
    padding: 0 20px;
  }
  
  .no-matches-text {
    text-align: center;
    font-size: 1.2rem;
    color: #d3d3d3;
    margin-bottom: 20px;
  }
  
  /* ───────────────── CARDS CONTAINER ───────────────── */
  .game-cards-container {
    display: grid;
    gap: 20px;
    /* Adjust column size as needed; you could use minmax for responsive layouts */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  /* ───────────────── INDIVIDUAL GAME CARD ───────────────── */
  .game-result-card {
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.2s ease;
  }
  
  .game-result-card:hover {
    transform: scale(1.02);
  }
  
  /* Opponent name styling */
  .opponent-name {
    font-size: 1.4rem;
    margin-bottom: 10px;
    border-bottom: 1px solid #404040;
    padding-bottom: 8px;
  }
  
  /* Victory/defeat styling */
  .game-outcome {
    font-size: 1.2rem;
    margin-bottom: 8px;
    font-weight: 600;
  }
  
  .victory {
    color: #7be07b; /* or #9a6529 if you prefer your gold highlight */
  }
  
  .defeat {
    color: #f57e7e;
  }
  
  /* Game date styling */
  .game-date {
    font-size: 0.95rem;
    color: #cfcfcf;
    margin-bottom: 12px;
  }
  
  /* Replay button styling */
  .replay-button {
    background-color: #444444;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    color: #fff;
    border: none;
    transition: background-color 0.2s ease;
  }
  
  .replay-button:hover {
    background-color: #666666;
  }
  
  /* ───────────────── RESPONSIVE TWEAKS ───────────────── */
  @media (max-width: 768px) {
    .banner-title {
      font-size: 2rem;
    }
  
    .game-cards-container {
      grid-template-columns: 1fr; /* Single column on mobile */
    }
  }