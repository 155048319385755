.add3dHover:hover #typhon,
.animate3dAttackAttacker #typhon {
  transform: translate3d(0%, -15%, 500px) scale(0.8, 0.8);
}

.add3dHover:hover #typhon_1,
.animate3dAttackAttacker #typhon_1 {
  transform: translate3d(0%, -5%, 500px) scale(0.8, 0.8);
}
.add3dHover:hover #noahsArk,
.animate3dAttackAttacker #noahsArk {
  transform: translate3d(0%, -15%, 500px);
}

.add3dHover:hover #hydra,
.animate3dAttackAttacker #hydra {
  transform: translate3d(0%, -5%, 500px) scale(0.8, 0.6);
}

.add3dHover:hover #nemeanLion,
.animate3dAttackAttacker #nemeanLion {
  transform: translate3d(-5%, -5%, 500px) scale(0.8, 0.8);
}

.add3dHover:hover #echidna,
.animate3dAttackAttacker #echidna {
  transform: translate3d(5%, -15%, 500px) scale(0.8, 0.8);
}

.add3dHover:hover #cthulu,
.animate3dAttackAttacker #cthulu {
  transform: translate3d(0%, -20%, 500px) scale(0.8, 0.8);
}

.add3dHover:hover #cthulu_1,
.animate3dAttackAttacker #cthulu_1 {
  transform: translate3d(0%, -10%, 500px) scale(0.8, 0.8);
}

.add3dHover:hover #cthulu_2,
.animate3dAttackAttacker #cthulu_2 {
  transform: translate3d(0%, -20%, 500px) scale(0.8, 0.8);
}

.add3dHover:hover #incubus,
.animate3dAttackAttacker #incubus {
  transform: translate3d(-5%, -15%, 500px) scale(1.5, 1);
}

.add3dHover:hover #azrael,
.animate3dAttackAttacker #azrael {
  transform: translate3d(-0%, -20%, 500px);
}

.add3dHover:hover #achilles,
.animate3dAttackAttacker #achilles {
  transform: translate3d(0%, -10%, 500px) scale(0.7, 0.7);
}

.add3dHover:hover #cerberusWrath,
.animate3dAttackAttacker #cerberusWrath {
  transform: translate3d(0%, -5%, 500px) scale(0.85, 0.85);
}

.add3dHover:hover .wrapper,
.animate3dAttackAttacker .wrapper {
  transform: perspective(900px) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper {
  transition: all 1s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 2s;
  position: absolute;
  left: 0;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 30%,
    rgba(12, 13, 19, 0.5) 51%,
    rgba(12, 13, 19) 81%
  );
}
.wrapper::after {
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    transparent 30%,
    rgba(12, 13, 19, 0.5) 51%,
    rgba(12, 13, 19) 81%
  );
}

.character {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 3s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.animate3dAttackAttacker .character {
  transition: all 1s;
}

.add3dHover:hover .wrapper::before,
.animate3dAttackAttacker .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.add3dHover:hover .character,
.animate3dAttackAttacker .character {
  opacity: 1;
  z-index: 20;
  transform: translate3d(0%, -10%, 500px);
}

.add3dHover:hover .cardName,
.animate3dAttackAttacker .cardName {
  color: rgba(255, 255, 255, 0.5);
  background: none;
}
