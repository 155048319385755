@media (min-width: 400px) and (max-width: 600px) {
  .messageText {
    width: 87%;
  }

  .halfField {
    width: 40%;
  }
}

@media screen and (max-width: 399px) {
  .messageText {
    width: 80%;
  }

  ::placeholder {
    font-size: 13px;
  }

  .halfField {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .cardStyleDisplay {
    overflow-x: scroll;
    justify-content: left;
  }
  /* .cardStyleDisplay .character { */
  /*TODO if we care about mobile/small screens*/
  /* } */
}

/* Media query for screen sizes between 1680 x 1050 and 1920 x 1020 */
@media (min-width: 1680px) and (max-width: 1919px),
  (min-height: 950px) and (max-height: 1200px) {
  .container {
    zoom: 80%;
    transform-origin: 0 0;
  }

  .side-menu-container {
    height: calc(100vh * 1.19);
  }

  #mainDivContainer {
    height: calc(97vh * 1.25);
  }

  .storeDisplay {
    height: calc(50vh * 1.25);
  }

  .fullHeight {
    height: calc(100vh * 1.25);
  }

  #TheirInfo {
    height: calc(10dvh * 1.25);
  }

  #TheirBoard {
    height: calc(30dvh * 1.25);
  }

  #MyBoard {
    height: calc(30dvh * 1.25);
  }

  #MyInfo {
    height: calc(2dvh * 1.25);
  }
}

@media (min-width: 800px) and (max-width: 1679px),
  (min-height: 600px) and (max-height: 949px) {
  .container {
    zoom: 70%;
    transform-origin: 0 0;
  }

  .side-menu-container {
    height: calc(100vh * 1.43);
  }

  #mainDivContainer {
    height: calc(97vh * 1.43);
  }

  .storeDisplay {
    height: calc(50vh * 1.43);
  }

  .fullHeight {
    height: calc(100vh * 1.43);
  }

  #TheirInfo {
    height: calc(10dvh * 1.43);
  }

  #TheirBoard {
    height: calc(30dvh * 1.43);
  }

  #MyBoard {
    height: calc(30dvh * 1.43);
  }

  #MyInfo {
    height: calc(2dvh * 1.43);
  }
}

@media screen and (max-width: 799px), screen and (max-height: 599px) {
  .container {
    padding-top: 60px;
    zoom: 50%;
    transform-origin: 0 0;
  }

  .volumeToggle {
    top: 10px;
  }

  #displayNavBarPhone {
    display: flex;
    z-index: 300;
  }

  .pointsNumber {
    font-size: 40px;
    position: absolute;
    top: 269px;
    right: 160px;
  }

  .pointDisplay {
    position: absolute;
    flex-direction: column;
    right: -250px;
  }

  .pointImg {
    width: 400px;
    height: 400px;
  }

  #mainDivContainer {
    height: calc(97vh * 2);
  }

  .storeDisplay {
    height: calc(50vh * 2);
  }

  .fullHeight {
    height: calc(100vh * 2);
  }

  #TheirInfo {
    height: calc(10dvh * 2);
  }

  #TheirBoard {
    height: calc(30dvh * 2);
  }

  #MyBoard {
    height: calc(30dvh * 2);
  }

  #MyInfo {
    height: calc(2dvh * 2);
  }
}

@media screen and (min-aspect-ratio: 21/9) and (max-aspect-ratio: 21/9) {
  .container {
    padding-top: 60px;
    zoom: 50%;
    transform-origin: 0 0;
  }
}
