.glossaryDiv {
    background: url("../background-images/standardBackground.jpeg");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 58.75px;
    bottom: 0px;
    z-index: 10000;
    color: white;
    font-size: 45px;
    display: flex;
    flex-direction: column;
    gap: 5%;
    padding-top: 1%;
  }
  
  .glossaryBanner {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 50px 20px;
    text-align: center;
    margin-bottom: 0;
  }
  
  .banner-title {
    font-size: 2rem;
    margin: 0 0 10px;
  }
  
  .banner-subtitle {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
  

  .glossaryContent {
    max-width: 95%;
    margin: 30px auto 0 auto;
    padding: 0 20px;
    font-size: 28px; 
  }
  

  .glossaryActions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem; 
    margin-bottom: 20px;
  }
  

  .searchBarCorrection {
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 0;
  }
  
  .searchBarCorrection input {
    color: #f1f1f1;
    border: none;
    padding: 8px;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
  }

  .searchBarCorrection .glossaryInputFix {
    bottom: 22%;
}

.glossarySpanFix {
    bottom: 20px;
}

.focusCorrection input:focus ~ span {
    transform: translateX(-10px) translateY(-35px);
}
  
  #fontSizeCorrection {
    font-size: 1.2rem;
    color: #d3d3d3;
    margin-left: 5px;
  }
  

  .closeGlossaryButton {
    background-color: #9a6529;
    border: none;
    padding: 12px 20px;
    font-size: 1.2rem;    
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .closeGlossaryButton:hover {
    background-color: #7e5224;
  }
  
  .glossaryEntries {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  

  .glossaryEntry {
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 20px;     
    margin-bottom: 10px;
    transition: transform 0.2s ease;
    font-size: 35px;   
  }
  

  .glossaryEntry:hover {
    transform: scale(1.01);
    background-color: #9a6529;
  }
  
 
  @media (max-width: 768px) {
    .banner-title {
      font-size: 1.6rem;
    }
  
    .glossaryContent {
      font-size: 24px; 
    }
  }