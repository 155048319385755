/* -- Overall wrapper mimicking your “store-wrapper” style -- */
.leaderboard-wrapper {
    background-color: rgba(0, 0, 0, 0.85);
    position: relative;
    min-height: 100vh;
    color: #f1f1f1;
    padding-bottom: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  
  /* -- Banner area at the top -- */
  .leaderboard-banner {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 50px 20px;
    text-align: center;
    margin-bottom: 0;
  }
  
  .banner-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .banner-subtitle {
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  /* -- Main content container (similar to “store-content”) -- */
  .leaderboard-content {
    max-width: 95%;
    margin: 30px auto 0 auto;
    padding: 0 20px;
  }
  
  /* -- A container to hold the table (optional) -- */
  .table-container {
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 20px;
  }
  
  /* -- Table styling similar to your store’s layout -- */
  .leaderboardtable {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden; /* Ensures border-radius applies to entire table */
  }
  
  .leaderboardtable thead {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .leaderboardtable thead th {
    color: #fff;
    padding: 12px;
    font-weight: 600;
    text-align: left;
  }
  
  .leaderboardtable tbody tr {
    border-bottom: 1px solid #444;
  }
  
  .leaderboardtable tbody tr:nth-child(even) {
    background-color: #1e1e1e;
  }
  
  .leaderboardtable td {
    padding: 12px;
    color: #f1f1f1;
  }

  div table tbody tr:hover {
    background-color: #9a6529;
  }

  .leaderboardtable tbody tr:nth-child(even):hover {
    background-color: #9a6529;
  }
  