.navLink {
  color: #9a6529;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  padding: 10px 5px;
  position: relative;
  flex-shrink: 0;
}

/* Pseudo-element for underline */
.navLink::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #e0953f;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

/* Hover state for link and underline */
.navLink:hover {
  color: hsl(32, 72%, 56%);
}

.navLink:hover::after {
  transform: scaleX(1); /* Expand the underline on hover */
}

/* Active state for link */
.navLink.active {
  color: #ff8800;
}

/* Underline color for the active link */
.navLink.active::after {
  background: #ff8800;
  transform: scaleX(1);
}

.side-menu-container > div {
  width: 100%;
}

.glossaryButton {
  font-size: 35px;
  color: #9a6529;
}

.glossaryButton:hover {
  color: #ff8800;
  cursor: pointer;
}

.navMenu {
  width: 220px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 561px) and (max-width: 990px) {
    width: 200px;
  }
  @media (min-width: 340px) and (max-width: 560px) {
    font-size: 12px;
    width: 100%;
    flex-direction: row;
    padding: 0;
  }
  @media screen and (max-width: 339px) {
    flex-direction: row;
    width: 100%;
    padding: 0;
  }
}

.navMenu a {
  text-decoration: none;
}

.playersOnline {
  position: absolute;
  top: -17px;
  left: 10px;
}

.pointDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20%;
  color: #9a6529;
}

@media (min-width: 800px) and (max-width: 1187px) {
  .pointDisplay {
    margin-right: 12%;
  }

  .playersOnline {
    left: 20px;
    position: absolute;
    top: -9px;
    zoom: 80%;
  }
}
