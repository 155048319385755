.chart-container {
    width: 100%;
    height: 300px;
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Tooltip container */
  .chart-tooltip {
    background-color: #1e1e1e;
    border: 1px solid #1e1e1e;
    border-radius: 6px;
    padding: 8px 12px;
    color: #f1f1f1;
    text-align: left;
  }
  
  .chart-tooltip-label {
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  /* Choose the "top" or lighter color from each gradient
     so it remains clearly visible on the .chart-tooltip background */
  .chart-tooltip-minions {
    color: #ba7c37; 
  }
  
  .chart-tooltip-spells {
    color: #a0601f; 
  }