.deckSelectedGameIcon {
  position: relative;
  height: 210px;
  width: 142px;
  margin: 5px;
  z-index: 1;
  background-size: cover;
  background-position: 50%;
  cursor: pointer;
}

.cardBackDefaultSettings {
  background-size: cover;
  background-position: 50%;
}

.defaultCardBack {
  background-image: url(../cardBacks/cardBackImages/defaultCardBack.png);
  color: white;
  background-size: cover;
  margin: -9px 0px -5px 0px;
}

.defaultCardBackAlternate {
  background-image: url(../cardBacks/cardBackImages/defaultBackAlternate.png);
  color: white;
  background-size: cover;
  margin: -4px 0px -5px 0px;
}

.zuesHandCardBack {
  background-image: url(../cardBacks/cardBackImages/resizedCardBack.jpg);
  color: black;
}

.zeusCardBack {
  background-image: url(../images/zeus.jpg);
  color: white;
}

.themisIncarnateCardBack {
  background-image: url(../images/themis.jpg);
  color: black;
}

.friggIncarnateCardBack {
  background-image: url(../images/friggIncarnate.jpg);
  color: white;
}

.hecateIncarnateCardBack {
  background-image: url(../images/hecateIncarnate.jpg);
  color: white;
}

.hephaestusIncarnateCardBack {
  background-image: url(../images/hephaestusIncarnate.jpg);
  color: black;
}

.khaosIncarnateCardBack {
  background-image: url(../images/chaos.jpg);
  color: white;
}

.satanCardBack {
  background-image: url(../images/satan.jpg);
  color: white;
}

.cerberusWrathCardBack {
  background-image: url(../images//cerberusWrath.jpg);
  color: black;
}

.lightningBoltCardBack {
  background-image: url(../images/lightningBolt.jpg);
  color: white;
}

.typhonCardBack {
  background-image: url(../images/typhon.jpg);
  color: white;
}

.kukulkanCardBack {
  background-image: url(../images/kukulkan.jpg);
  color: white;
}

.theaIncarnateCardBack {
  background-image: url(../images/theaIncarnate.jpg);
  color: black;
}

.aresCourageCardBack {
  background-image: url(../images/aresCourage.jpg);
  color: white;
}

.poseidonCardBack {
  background-image: url(../images/poseidon.jpg);
  color: white;
}

.krakenCardBack {
  background-image: url(../images/kraken.jpg);
  color: white;
}

.neameanLionCardBack {
  background-image: url(../images/nemeanLion.jpg);
  color: white;
}

.athenaOfWarCardBack {
  background-image: url(../images/athenaOfWar.jpg);
  color: black;
}

.theLadyOfTheLakeCardBack {
  background-image: url(../images/theLadyOfTheLake.jpg);
  color: black;
}

.twilightLuciferCardBack {
  background-image: url(../images/twilightLucifer.jpg);
  color: white;
}

.fenrirCardBack {
  background-image: url(../images/fenrir.jpg);
  color: white;
}

.thorCardBack {
  background-image: url(../images/thor.jpg);
  color: white;
}

.jormungandrCardBack {
  background-image: url(../images/jormungandr.jpg);
  color: white;
}

.azathothCardBack {
  background-image: url(../images/azathoth.jpg);
  color: white;
}

.cthulhuCardBack {
  background-image: url(../images/cthulu.jpg);
  color: white;
}

.zeusGodAboveAllCardBack {
  background-image: url(../images/godAboveAll.jpg);
  color: white;
}

.sereneForestCardBack {
  background-image: url(../cardBacks/cardBackImages/sereneForest.jpg);
  color: black;
}

.volsungCardBack {
  background-image: url(../images/volsung.jpg);
  color: white;
}

.quetzalcoatlCardBack {
  background-image: url(../images/quetzalcoatl.jpg);
  color: black;
}

.lernaeanHydraCardBack {
  background-image: url(../images/lernaeanHydra.jpg);
  color: white;
}

.typhonIncarnateCardBack {
  background-image: url(../images/typhonIncarnate.jpg);
  color: white;
}

.hadesCardBack {
  background-image: url(../images/hades.jpg);
  color: white;
}

.raTheSolarPharoahCardBack {
  background-image: url(../images/raTheSolarPharoah.jpg);
  color: white;
}

.isisTheResurrectorCardBack {
  background-image: url(../images/isisTheResurrector.jpg);
  color: white;
}

.sobekCardBack {
  background-image: url(../images/sobek.jpg);
  color: white;
}

.gabrielCardBack {
  background-image: url(../images/gabriel.jpg);
  color: white;
}

.luciferFallenAngelCardBack {
  background-image: url(../images/luciferFallenAngel.jpg);
  color: white;
}

.luciferLightbringerCardBack {
  background-image: url(../images/luciferLightbringer.jpg);
  color: white;
}

.michaelCardBack {
  background-image: url(../images/michael.jpg);
  color: white;
}

.michaelGuardianOfSoulsCardBack {
  background-image: url(../images/michaelGuardianOfSouls.jpg);
  color: white;
}
