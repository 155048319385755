.container-test {
  display: grid;
  gap: 0px 0px;
  margin-top: 39px;
}

#hand:hover {
  position: relative;
  bottom: 150px;
  z-index: 30;
}

#hand > .parentAnchor {
  transition: bottom 1.5s ease;
  bottom: -300px;
  position: absolute;
  z-index: 1;
}

#hand > .parentAnchor:hover {
  bottom: -150px;
  transform: none;
  z-index: 35;
}

.deck div {
  padding: 5px;
  border-radius: 5px;
  font-size: 25px;
}

.cardInTheirHand:after,
.deck:after {
  content: "";
  /* background-color: #0000002e; */
  width: 90px;
  height: 134px;
  position: absolute;
}

.myGY,
.statDisplay {
  display: flex;
  justify-content: flex-start;
}

#ourBoard {
  display: flex;
  justify-content: space-between;
}

#mana {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 29px;
}

#myBoard,
#theirBoard {
  justify-content: center;
  width: 100%;
  height: 331px;
}

#theirBoard {
  margin-bottom: 50px;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
}

#myBoard {
  margin-top: 50px;
}

#information {
  width: 100%;
}

#myPlayerInfo,
#theirPlayerInfo {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 29px;
  padding: 0;
  position: relative;
  z-index: 1;
}

#myPlayerInfo:hover,
#theirPlayerInfo:hover {
  cursor: pointer;
}

#playerName {
  color: white;
  font-size: 29px;
}

#exileZone {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  left: -231px;
  height: 400px;
  width: 230px;
  display: flex;
  flex-direction: column;
}

.cardInTheirHand,
.deck {
  width: 90px;
  height: 134px;
  margin: 10px;
  align-self: center;
  border-radius: 5px;
}

#theirInformation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 180px;
}

.expandedGY {
  display: flex;
  width: 900px;
  height: 375px;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  z-index: 40;
}

.myExpandedGY {
  bottom: 3px;
}

#TheirInfo {
  display: flex;
  height: 10dvh;
  grid-area: TheirInfo;
}

#MyBoard, #TheirBoard {
  height: 30dvh;
  flex-direction: row;
  display: flex;
}
#MyInfo {
  bottom: 0;
  height: 2dvh;
  position: fixed;
  width: 97%;
}

#playerInfo {
  right: 40px;
  position: fixed;
}

.container-test .space-between {
  position: fixed;
  display: block;
  top: 51%;
}

.container-test .deckAndGY {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 70px;
  position: fixed;
  /*width: 100%;*/
  bottom: 0;
  z-index: 1;
}

.container-test #gameFunctionButton {
  z-index: 100;
  position: fixed;
  top: 50%;
  right: 0;
}

.parentAnchor {
  perspective: 1000px;
}

/* Initial state of the card, ensuring we can animate it back */
.card {
  transition: transform 0.5s ease;
  transform-style: preserve-3d; /* Make sure 3D transformations apply correctly */
}

@keyframes attackAnimation {
  0% {
    transform: translateZ(0) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
  }
  25% {
    transform: translate3d(0, 0, 50px) rotate3d(1, 0, 0, -15deg); /* Start lifting and tilting forward */
  }
  50% {
    /* Mid-point: Move forward, flip to show the bottom, and dive down */
    transform: translateZ(50px)
      translate3d(
        var(--attack-distance-x, 0px),
        var(--attack-distance-y, 0px),
        100px
      )
      rotate3d(1, 0, 0, -45deg);
  }
  75% {
    /* Recoil effect while showing the card's bottom to the viewer */
    transform: translateZ(0)
      translate3d(
        calc(var(--attack-distance-x, 0px) / 2),
        calc(var(--attack-distance-y, 0px) / 2),
        -50px
      )
      rotate3d(1, 0, 0, -25deg);
  }
  100% {
    /* Return to facing the defender directly, but with a slight hover effect for dramatic finish */
    transform: translate3d(
        var(--attack-distance-x, 0px),
        var(--attack-distance-y, 0px),
        0
      )
      rotate3d(0, 1, 0, 0deg);
  }
}

.animate-attack {
  z-index: 100;
  animation: attackAnimation 2s cubic-bezier(0.68, -0.55, 0.27, 0.55) forwards;
}

.container {
  zoom: 100%;
  transform-origin: 0 0;
}

.counterSpellSpan:hover #hideDisplay,
.lifeAndStatus:hover #hideDisplay {
  display: flex;
  bottom: 30px;
}

.lifeAndStatus,
.myLife,
.opponentLife {
  margin-right: 10px;
}

.counterSpellSpan:hover #hideDisplayOpponent,
.lifeAndStatus:hover #hideDisplayOpponent {
  display: flex;
  bottom: -208px;
}

.deckAndGY {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 335px;
  gap: 70px;
}

.theirDeckAndGY {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 20%;
}

#revealedHand {
  display: flex;
  transform: scale(0.6);
  align-items: center;
  justify-content: center;
  width: 240%;
  height: 340px;
  position: relative;
  top: -145px;
  z-index: 100;
}

.myFriendlyButton {
  position: absolute;
  left: -36px;
  bottom: 334px;
  text-wrap: nowrap;
}

.theirNonFriendlyButton {
  position: absolute;
  top: 469px;
  left: -3px;
}

#mana {
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(93, 70, 70, 0); /* subtle shadow for depth */
}

/* Initial state of the glow */
@keyframes glow {
  from {
    box-shadow: 0 0 20px rgba(217, 91, 6, 0.9);
  }
  to {
    box-shadow: 0 0 30px lightorange;
  }
}

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px lightorange; /* Adjust light orange glow here */
  }
  100% {
    transform: scale(1);
  }
}

/* Animation triggers */
.mana-change {
  animation: pulse 1s ease-in-out infinite,
    glow 1.5s ease-in-out infinite alternate;
}

.graveyardCloseButton {
  position: fixed;
  left: 904px;
  font-size: 35px;
  z-index: 200;
  cursor: pointer;
  text-align: center;
}
