.parentAnchor {
  transition: all 1.5s ease;
  transform: translate(0, 0) scale(1);
  position: relative;
  display: 'flex';
  height: 335px;
  width: 230px;
  user-select: none;
}

#cardName,
#cardType,
#shrunkCardName,
#mythosAndStats,
.cardText {
  background: #faebd773;
  padding: 2px 3px;
}

#casting,
#attacked,
#elusive,
#provoke,
#frozen,
.deckIconSelectedDeck {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

#casting::before,
#attacked::before,
#elusive::before,
#frozen::before,
#provoke::before,
.deckIconSelectedDeck::before,
#casting::after,
#attacked::after,
#elusive::after,
#frozen::after,
#provoke::after,
.deckIconSelectedDeck::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 220px;
  height: 325px;
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

#casting::after,
#attacked::after,
#elusive::after,
#frozen::after,
#provoke::after,
.deckIconSelectedDeck::after {
  animation-delay: -2s;
}

#casting::before,
#casting::after {
  background: linear-gradient(60deg, transparent, gold, gold);
}

#attacked::before,
#attacked::after,
.deckIconSelectedDeck::before,
.deckIconSelectedDeck::after {
  background: linear-gradient(60deg, transparent, red, red);
}

#elusive::before,
#elusive::after {
  background: linear-gradient(60deg, transparent, gray, gray);
}

#frozen::before,
#frozen::after {
  background: linear-gradient(60deg, transparent, blue, blue);
}

#provoke::before,
#provoke::after {
  background: linear-gradient(60deg, transparent, green, green);
}

#selectedCardback {
  outline: 5px solid red;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.zIndex30 {
  z-index: 30;
}

.cardImageHolder {
  height: 150px;
  width: 100%;
  padding: 0px 10px;
  margin-top: 24px;
}

.cardTyping {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 11px;
  font-family: "Cinzel", serif;
  font-weight: 1000;
  background: #0000006d;
  color: white;
  padding-left: 5px;
}

.cardTextBox,
.modifiedCardTextBox {
  overflow: hidden;
  height: 113px;
  white-space: pre-line;
  font-family: "Mali", cursive, sans-serif;
  font-weight: 600;
  color: white;
  z-index: 30;
}

.cardTextBox:hover,
.modifiedCardTextBox:hover {
  overflow-y: auto;
}

.modifiedCardTextBox {
  height: 85px;
}

.belowTextCardBox {
  width: 220px;
  height: 130px;
  z-index: 30;
  position: absolute;
  top: 179px;
  left: 4px;
}

.zIndexBoost {
  z-index: 40;
  cursor: pointer;
}

.cardName {
  display: flex;
  width: 220px;
  color: white;
  justify-content: space-between;
  font-family: "Cinzel", serif;
  font-weight: 1000;
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 30;
}

.cardName,
.cardTextBox,
.modifiedCardTextBox {
  background: #00000045;
  padding: 2px 3px;
}

.mythosAndStats {
  display: flex;
  color: white;
  justify-content: space-between;
  font-family: "Cinzel", serif;
  font-weight: 1000;
  width: 215px;
  position: absolute;
  top: 309px;
  left: 9px;
  z-index: 30;
}

.normalCard,
.fullArtCard,
.threeDCard {
  transition: all 1.5s ease;
  display: flex;
  z-index: 1;
  position: relative;
  height: 325px;
  width: 220px;
  margin: 5px;
  cursor: pointer;
}

.normalCard:hover,
.fullArtCard:hover,
.add3dHover:hover {
  cursor: pointer;
}

.normalCard {
  background-image: url(/src/background-images/cardbg1.jpg);
  color: #000;
  flex-flow: column;
  text-overflow: hidden;
  border-radius: 10px;
}

.fullArtCard {
  flex-direction: column;
  perspective: 2500px;
}

.threeDCard {
  flex-direction: column;
  perspective: 2500px;
}

.applyYellow {
  color: yellow;
}
