.contactBox {
  position: relative;
  width: 600px;
  height: 600px;
  background: #1c1c1c;
  border-radius: 50px 5px;
  overflow: hidden;
}

.contactBox::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 600px;
  height: 600px;
  background: linear-gradient(60deg, transparent, #9a6529, #9a6529);
  transform-origin: bottom right;
  animation: animate 36s linear infinite;
}

.contactBox::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 600px;
  height: 600px;
  background: linear-gradient(60deg, transparent, #9a6529, #9a6529);
  transform-origin: bottom right;
  animation: animate 36s linear infinite;
  animation-delay: -18s;
}

.contactBox form {
  position: absolute;
  inset: 2px;
  border-radius: 50px 5px;
  background: #28292d;
  z-index: 10;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
}

.contactBox h2 {
  color: #9a6529;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.contactInputBox {
  position: relative;
  width: 300px;
  margin-top: 35px;
}

.contactInputBox input {
  position: relative;
  width: 100%;
  padding: 10px 10px 13px;
  background: transparent;
  border: none;
  outline: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  z-index: 10;
}

.contactInputBox span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.contactInputBox input:valid ~ span,
.contactInputBox input:focus ~ span {
  color: #9a6529;
  transform: translateX(-10px) translateY(-30px);
  font-size: 0.75em;
}

.contactInputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #9a6529;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.contactInputBox input:valid ~ i,
.contactInputBox input:focus ~ i {
  height: 44px;
}

.contactInputBoxMessage {
  position: relative;
  width: 500px;
  margin-top: 35px;
}

.contactInputBoxMessage textarea {
  position: relative;
  width: 100%;
  padding: 10px 10px 220px;
  background: transparent;
  border: none;
  outline: none;
  color: #23242a;
  font-size: 25px;
  overflow-y: hidden;
  z-index: 10;
}

.contactInputBoxMessage span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.contactInputBoxMessage textarea:valid ~ span,
.contactInputBoxMessage textarea:focus ~ span {
  color: #9a6529;
  transform: translateX(-10px) translateY(-30px);
  font-size: 0.75em;
}

.contactInputBoxMessage i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #9a6529;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.contactInputBoxMessage textarea:valid ~ i,
.contactInputBoxMessage textarea:focus ~ i {
  height: 290px;
}

.contactBox .contactInputBox input:-webkit-autofill,
.contactBox .contactInputBox input:-webkit-autofill:hover,
.contactBox .contactInputBox input:-webkit-autofill:focus,
.contactBox .contactInputBox input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #9a6529 inset !important;
  height: 44px;
}

.contactBox input[type="submit"] {
  font-size: 20px;
  border: none;
  outline: none;
  background: #9a6529;
  padding: 5px;
  margin-top: 15px;
  border-radius: 90px;
  font-weight: 600;
  cursor: pointer;
}
