* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

/* <===== chart css =====> */
.custom-tooltip {
  background-color: rgb(198, 183, 183);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
/* ________________________________________________ */

/* potentially add rotating border */

.canClick {
  cursor: pointer;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.accountPageBox {
  display: flex;
  flex-direction: column;
}

.accountPageBoxCentering {
  display: flex;
  justify-content: center;
}

.optionWidth {
  width: 200px;
}

.leaderboardContainer {
  display: flex;
  flex-direction: column;
}

.leaderboardTable {
  border-collapse: collapse;
  width: 70%;
  background-color: white;
  align-self: center;
}

.gamePageMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #28292df0;
}

.leaderboardTable th,
.leaderboardTable td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  color: black;
}

#sideboard-container {
  height: 100%;
  max-height: 100%;
}

.deckLimitExceeded {
  background-color: red;
}

.centerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yourDeck {
  height: 380px;
}

.infoText {
  width: 100%;
  font-size: 40px;
  text-align: center;
}

#youtubeVideo {
  display: flex;
  justify-content: center;
}

.gameFontColors {
  color: white;
}

.counterSpellSpan {
  margin-left: 10px;
}

#hideDisplayOpponent {
  display: none;
}

.userName {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardStoreDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storeDisplayContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pointDisplayCost {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: 50px;
  color: #9a6529;
  background-image: linear-gradient(rgb(54, 54, 54), rgb(24, 21, 21));
  border: 1px solid #9a6529;
  border-radius: 4px;
  padding: 5px 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 45px;
}

.pointDisplayCostAlignment {
  margin-top: 50px;
}

.pointDisplayCostCardBack {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: 50px;
  color: #9a6529;
  background-image: linear-gradient(rgb(54, 54, 54), rgb(24, 21, 21));
  border: 1px solid #9a6529;
  border-radius: 4px;
  padding: 5px 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: -12px;
}

.cardBackPageStyling {
  margin: 23px;
}

.pointImgStore {
  width: 75px;
  height: 75px;
  margin-top: 20px;
  margin-right: 5px;
}

.pointImg {
  width: 75px;
  height: 75px;
  margin-top: 20px;
}

.devTag {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Audiowide", cursive;
  font-size: 25px;
  color: #7b7b7b;
  background-image: linear-gradient(
    to bottom,
    rgb(84, 81, 81),
    rgb(39, 38, 38),
    rgb(0, 0, 0)
  );
  border: 5px solid;
  border-image: linear-gradient(to top left, rgb(102, 68, 68), rgb(94, 94, 94))
    1;
}

#usedAbility {
  border: 10px solid red;
}

#hand {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25%;
  justify-content: center;
  bottom: -50px;
  width: 100%;
  position: relative;
  transition: bottom 1s ease;
}

#graveyard {
  display: flex;
  flex-wrap: wrap;
}

#mainDivContainer {
  padding: 1% 1.5%;
  user-select: none;
  height: 97vh;
  overflow: hidden;
}

.overFlowScroll {
  overflow-x: scroll;
}

.align-self-center {
  align-self: center;
}

.displayFlex {
  display: flex;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

#manaCurveChart {
  background-image: url(/src/background-images/cardbg1.jpg);
  width: 450px;
  height: 200px;
}

.biggerText {
  font-size: 25px;
}

.cardSearchBar {
  font-size: 30px;
  margin-left: 1.5%;
  border-radius: 10px;
  border: 0px;
  padding: 5px 10px;
  position: relative;
  z-index: 100;
  margin-top: 10px;
  margin-right: 5px;
  background: #9a6529;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  padding: 3px;
  background-color: #faebd773;
  margin: 0px 1px;
}

.attacked {
  color: #c71414;
  background: #00000045;
}

.elusive {
  color: #777676;
  background: #00000045;
}

.frozen {
  color: #1494c7;
  background: #00000045;
}

.provoke {
  color: rgb(14, 133, 73);
  background: #00000045;
}

.cardImageFormatting {
  align-self: center;
  width: 100%;
  height: 150px;
  background-size: cover;
}

.glossaryDiv {
  background: url("../background-images/standardBackground.jpeg");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 58.75px;
  z-index: 10000;
  color: white;
  overflow: auto;
  font-size: 45px;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding-top: 1%;
}

#fontSizeCorrection {
  font-size: 20px;
  padding-top: 30px;
}

.cardText {
  overflow: hidden;
  height: 100px;
  white-space: pre-line;
  border-top: 1px solid #6f4317;
  font-family: "Mali", cursive, sans-serif;
  font-weight: 600;
}

/* TO UPDATE TO SPECIFIC LONGER TEXT CARDS ONLY, LATER */
.cardText:hover {
  overflow-y: auto;
}

#mythosAndStats {
  display: flex;
  justify-content: space-between;
  font-family: "Cinzel", serif;
  font-weight: 1000;
}

#cardImage {
  align-self: center;
  width: 100%;
  height: 150px;
  background: black;
}

.biggerButton {
  font-size: 20px;
  border: none;
  outline: none;
  background: #9a6529;
  padding: 5px;
  margin: 5px;
  border-radius: 90px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
}

.biggerButton:hover {
  cursor: pointer;
}

.turnPosition {
  position: fixed;
  top: 40%;
}

#gameFunctionButton {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.textAlign {
  text-align: center;
}

.deck {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: 50%;
  background-size: cover;
  width: 90px;
  height: 134px;
}

.basicCenter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.storeDisplay {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

#hoverCardText {
  overflow: none;
  height: 180px;
  border: 3px solid;
  border-image: linear-gradient(
      to top right,
      rgb(25, 59, 97),
      rgb(234, 241, 255),
      rgb(10, 62, 97),
      rgb(28, 113, 146),
      rgb(36, 4, 180)
    )
    1;
  white-space: pre-line;
}

#cardName {
  display: flex;
  align-self: flex-start;
  width: 100%;
  justify-content: space-between;
  font-family: "Cinzel", serif;
  font-weight: 1000;
}

.shrunkCardName {
  font-size: 12px;
  transform: scaleY(1);
  margin-top: 2px;
}

.largerShrunkCardName {
  font-size: 11px;
  transform: scaleY(1.2);
  margin-top: 2px;
}

#cardType {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 11px;
  font-family: "Cinzel", serif;
  font-weight: 1000;
}

#cardStats {
  display: flex;
  align-self: flex-end;
  margin-right: 5px;
}

#damaged {
  color: red;
}

#deckBuilder {
  display: flex;
  padding: 10px 0;
}

#cardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.basicFlexDisplay {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.fullHeight {
  height: 100vh;
}

.theirMythics {
  height: 380px;
}

.sideboard {
  height: 380px;
}

.textCenter {
  text-align: center;
}

.currentDeck,
.loadDecks {
  padding: 0px 0px 20px 0px;
  background-color: #2d2d2d;
  box-shadow: 0px 0px 17px 6px #1e1e1e;
  margin: 10px 0px;
  border-radius: 10px;
  color: white;
}

.currentDeckLength {
  font-size: 18px;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.currentDeckCard,
.loadDeckName {
  cursor: pointer;
  padding: 3px 20px;
  position: relative;
}

.redIcon,
.loadDeckIcon {
  color: red;
  display: inline-block;
  position: absolute;
  right: 35px;
}

.currentDeckCard:hover {
  background-color: lightcoral;
}

.loadDeckName:hover {
  background-color: rgb(184 214 208);
}

.gamePageDeckIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.gamePageDeckList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}

#cardsInTheirHand {
  display: flex;
  width: 60%;
  justify-content: center;
  align-self: flex-end;
  flex-direction: row;
}

.abilityName,
.abilityDescription {
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

html,
body {
  background-image: url(../background-images/standardBackground.jpeg);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
/* Form */
/* ____________________________________________ */

#formList {
  display: flex;
  list-style: none;
  margin: 10px;
  padding: 0;
  gap: 10px;
}

.formButton {
  background: rgba(0, 0, 0, 0);
  border: 1px solid black;
  color: black;
}

#contactBox label {
  font-size: 27px;
}

#contactBox {
  display: flex;
  justify-content: center;
  background-color: white;
  max-width: 480px;
  margin: 50px auto;
  border-radius: 10px;
  box-shadow: 0px 0px 17px 6px #2d2d2d;
}

.halfField {
  width: 50%;
  margin: 10px;
}

.inputBoxes {
  display: flex;
}

.iconDiv {
  background: #00000045;
}

.messageText {
  padding: 0;
  border: black solid 1px;
  box-sizing: border-box;
  margin: 10px -1px;
}

.messageInputDiv {
  display: flex;
  flex-direction: column;
  margin-left: 2.5%;
}

textarea {
  resize: none;
}

.seeThrough {
  background: rgba(197, 195, 195, 0.356);
  padding: 10px;
  border-radius: 7px;
  margin: 5px 0px;
}

.needsBorder {
  border: 1px black solid;
}

.seeThrough::placeholder {
  color: rgb(0, 0, 0, 0.5);
  font-style: italic;
}
/* Home Page */
/* ____________________________________________ */

#cardInfoCentered {
  display: flex;
  justify-content: center;
}

#cardTextInformation {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.removeBlueLink {
  color: white;
}
/* EndGame Screen */
/* _____________________________________________ */
#endGameScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  height: 300px;
  font-size: 50px;
  gap: 20%;
}

#endGameBox {
  display: flex;
  justify-content: center;
}

/* User selection box */
/* ___________________________________________________________ */
#userSelectionBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  min-height: 100px; /* Set a minimum height */
  font-size: 40px;
  position: absolute;
  top: 60%;
  z-index: 100;
}
#optionBoxDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(
    to bottom right,
    rgb(76, 76, 76, 0.7),
    rgb(255, 255, 255, 0.7),
    rgba(76, 76, 76, 0.7)
  );
  width: fit-content;
  margin-left: 10%;
  margin-right: 10%;
  border: solid 10px;
  border-image: linear-gradient(
      to top right,
      rgb(0, 0, 0),
      rgb(117, 117, 117),
      rgb(0, 0, 0),
      rgb(117, 117, 117)
    )
    1;
  gap: 50px;
}

.handPlus {
  justify-content: center;
  align-items: center;
  display: flex;
}

.playerSelectionOptions {
  cursor: pointer;
  text-align: center;
}

div#hoverCardText {
  padding: 3px;
  height: 100px;
}

/* ScrollBAR STYLES */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9A6529;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

nav a.dXkKtK,
.checkBoxLabel {
  color: #9a6529;
}

.checkBoxLabel:hover {
  cursor: pointer;
}

nav a.dXkKtK:hover {
  color: #b9885a;
}

/* Sidemenu */
/* ______________________________________________________________ */
.side-menu-container.sticky {
  position: sticky;
  top: 0;
}
.side-menu-container {
  width: 220px;
  height: 100vh;
  margin: 0;
  top: 56px;
  left: 0;
  display: flex;
  position: absolute;
  justify-content: center;
  background-image: linear-gradient(
    rgb(24, 21, 21),
    rgb(54, 54, 54),
    rgb(24, 21, 21)
  );
  background-repeat: round;
  font-size: 25px;
  font-family: arial;

  @media (min-width: 561px) and (max-width: 990px) {
    width: 200px;
    font-size: 20px;
  }

  @media (min-width: 340px) and (max-width: 560px) {
    font-size: 12px;
    width: 100%;
    flex-direction: row;
    float: inline-start;
    height: 50px;
  }

  @media screen and (max-width: 339px) {
    width: 75px;
    font-size: 11px;
    width: 100%;
  }
}

.logout {
  display: inline;
  bottom: 0;
  position: relative;
  font-size: small;
  justify-content: center;
}
/* Collection/Profile */
/* ______________________________________________________________ */
.profile-content {
  position: relative;
  flex-grow: 1;
  overflow: overlay;
  background-color: rgba(0, 0, 0, 0.85);
}

.cardCollectionContainer {
  width: 100%;
  background: rgba(12, 13, 19, 0.95);
  color: white;
  padding: 10px 0;
  height: 100%;
}

.cardCollectionContainer h1 {
  display: flex;
  width: 50%;
  min-width: 500px;
  align-items: center;
  justify-content: center;
}
.cardCollectionContainer h1 span {
  margin: 0 10px;
}
.cardDetail {
  min-width: 500px;
}

.cardDetail .storeDisplay {
  display: flex;
  width: 50%;
  min-width: 500px;
  align-items: center;
  justify-content: center;
  height: auto;
  top: 100px;
}
.cardInfo {
  min-width: 500px;
  max-width: 50%;
  padding: 30px;
}

.cardStyleDisplay {
  position: relative;
  min-width: 500px;
  justify-content: center;
  max-height: 355px;
}

.cardDisplay {
  display: inline-flex;
  height: auto;
}

.cardDisplay > div {
  position: relative;
  float: inline-start;
  display: block;
  box-sizing: content-box;
  /* left: 20%; */
}
.cardDetail .cardCollectionContainer {
  display: block;

  left: 0;
  color: white;
}
.cardDetail .type {
  display: inline-block;
}

.cardDetail .rarity {
  float: inline-end;
}

.unowned {
  position: relative;
}
.unowned > div > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  cursor: auto !important;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  opacity: 0.8;
}

.unowned::after {
  font-size: x-large;
  content: "UNOWNED";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.owned {
  position: relative;
  color: white;
}
.owned > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  cursor: auto !important;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  opacity: 0.8;
}

.owned::after {
  font-size: x-large;
  content: "OWNED";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.maxCopies {
  position: relative;
  color: white;
  cursor: pointer;
}
.maxCopies > div::before {
  cursor: pointer;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  cursor: auto !important;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  opacity: 0.8;
}

/* .maxCopies::after {
  font-size: x-large;
  content: "2/3 COPIES";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
} */

.cardBackOwned {
  position: relative;
  color: white;
}
.cardBackOwned > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  cursor: auto !important;
  right: 10px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  opacity: 0.8;
}

.cardBackOwned::after {
  font-size: x-large;
  content: "OWNED";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
/* styles.css */
.showNavbarToggle {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 20px;
  position: absolute; /* Position absolute */
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
}

.showNavbarToggle span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: rgb(168, 96, 32);
  margin-bottom: 5px;
}

.showNavbarToggle.active span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.showNavbarToggle.active span:nth-child(2) {
  opacity: 0;
}

.showNavbarToggle.active span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.settingsMenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.collectionTab {
  display: none;
}

.collectionTab.active {
  display: block;
}

.fullScreenToggle {
  position: absolute;
  right: 64px;
  top: 18px;
  background: #00000000;
  color: rgb(168, 96, 32);
  cursor: pointer;
}

.volumeToggle {
  color: rgb(168, 96, 32);
  position: absolute;
  right: 100px;
  top: 18px;
  cursor: pointer;
}

.deckIconSelectedDeck::after,
.deckIconSelectedDeck::before {
  height: 210px;
  width: 142px;
}

.clicktocast::after {
  font-size: x-large;
  font-weight: bold;
  color: white;
  content: "Click to Cast";
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 42;
}
.clicktocast::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 41;
  opacity: 0.8;
}

.clicktotarget::after {
  font-size: x-large;
  font-weight: bold;
  color: white;
  content: "Click a Target";
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 42;
}
.clicktotarget::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 41;
  opacity: 0.8;
}

.glow-button {
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Keyframes for the pulsing effect */
@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 0 0 rgba(217, 91, 6, 0.9); /* Adjust color to match the button or desired glow */
  }
  50% {
    box-shadow: 0 0 1.5em 1.5em rgba(217, 91, 6, 0); /* Glow spreads */
  }
  100% {
    box-shadow: 0 0 0 0 rgba(217, 91, 6, 0); /* Glow resets to the initial state */
  }
}

.glow-button {
  animation: pulse-glow 2s infinite;
}

.cardListOptionsBox {
  display: flex;
}

.inputSearchBar {
  position: relative;
  width: 300px;
  margin-top: 0px;
  margin-left: 1.5%;
  margin-right: 5px;
}

.inputSearchBar input {
  position: relative;
  width: 100%;
  padding: 10px 10px 13px;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 30px;
  letter-spacing: 0.05em;
  z-index: 10;
  top: 7px;
}

.inputSearchBar span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 2em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.inputSearchBar input:valid ~ span,
.inputSearchBar input:focus ~ span {
  color: white;
  transform: translateX(-10px) translateY(-17px);
  font-size: 0.75em;
}

.inputSearchBar i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #1e1e1e;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputSearchBar input:valid ~ i,
.inputSearchBar input:focus ~ i {
  height: 44px;
}

.inputSearchBar input:-webkit-autofill,
.inputSearchBar input:-webkit-autofill:hover,
.inputSearchBar input:-webkit-autofill:focus,
.inputSearchBar input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #9a6529 inset !important;
  height: 44px;
}

.spacer {
  height: 50px;
}