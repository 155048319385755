.stats-wrapper {
    /* Subtle angled gradient background for a modern look */
    background: linear-gradient(135deg, #1e1e1e 0%, #2b2b2b 100%);
    min-height: 100vh;
    color: #f1f1f1;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    flex-direction: column;
  }
  
  /* Banner area at top (like a hero section) */
  .stats-banner {
    text-align: center;
    padding: 60px 20px 60px 20px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .stats-banner-title {
    font-size: 2.6rem;
    margin: 0 0 10px;
  }
  
  .stats-banner-subtitle {
    font-size: 1.15rem;
    line-height: 1.5;
    max-width: 700px;
    margin: 0 auto;
    opacity: 0.9;
  }
  
  /* Main content container */
  .stats-content {
    max-width: 90%;
    margin: 40px auto;
    flex: 1; /* helps stretch if needed */
  }
  
  /* Each section below the banner */
  .stats-section {
    margin-bottom: 40px;
  }
  
  .stats-section-title {
    font-size: 1.8rem;
    margin-bottom: 15px;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 2px solid #444444;
    text-transform: capitalize;
    letter-spacing: 0.5px;
  }
  
  /* Grid layout for card displays */
  .stats-card-grid {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 20px;
  }
  
  /* For large lists we want a scrollable container. Adjust the height as desired. */
  .scrollable-grid {
    max-height: 500px;
    overflow-y: auto;
  }
  
  /* Each card tile in the grid */
  .stats-card-tile {
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(4px); /* "Glass" effect if supported */
    border-radius: 10px;
    padding: 15px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Subtle box shadow for 'card' effect */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  }
  
  .stats-card-tile:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  }
  
  /* Additional card info at bottom (win %, times played) */
  .stats-card-info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }
  
  .stats-winrate {
    font-weight: 600;
    font-size: 1rem;
  }
  
  .stats-played {
    font-size: 0.9rem;
    color: #d3d3d3;
  }
  
  /* 
    Optional scrollbar styling for scrollable areas
    (Chrome, Edge, Safari)
  */
  .scrollable-grid::-webkit-scrollbar {
    width: 8px;
  }
  .scrollable-grid::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 4px;
  }

  .statCarouselContainer {
    height: 500px;
  }

  .year-selection {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem; /* space between label and select */
  }
  
  .year-selection label {
    font-size: 1rem;
    color: inherit; /* inherit color from .gameFontColors if that sets text color */
  }
  
  .year-selection select {
    background-color: #1f1f1f; /* example dark background to match a dark theme */
    color: #fff;              /* example text color */
    border: 1px solid #ddd;   /* adjust to match your theme */
    border-radius: 4px;
    padding: 0.3rem 0.6rem;
    font-size: 1rem;
  }
  
  /* If your theme uses other variables or classes, adjust accordingly */
  .gameFontColors {
    color: #fff; /* for dark theme, if desired */
    /* background-color: #000;  // or whatever primary BG color */
  }
  
  .biggerText {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }