
.cardList-wrapper {
    /* A flexible wrapper around filters, results, pagination */
    background-color: transparent; /* or a subtle gradient if desired */
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  /* ───────────────── FILTER BAR ───────────────── */
  .cardListOptionsBox {
    background-color: #2b2b2b; /* matching store “tabs-nav” or content box color */
    padding: 12px 20px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
  
  /* The <select> and <input> in the filter bar */
  .cardSearchBar {
    background-color: #1e1e1e;
    color: #f1f1f1;
    border: 1px solid #444;
    border-radius: 6px;
    padding: 8px 10px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.2s ease;
    cursor: pointer;
  }
  
  .cardSearchBar:hover,
  .cardSearchBar:focus {
    border-color: #9a6529;
  }
  
  /* The input container for typed searches */
  .inputSearchBar {
    /* position: relative;
    width: 180px;  */
    /* Adjust as needed, or make it flexible with flex:1 if you prefer */
  }
  
  /* .inputSearchBar input {
    width: 100%;
    padding: 8px 10px;
    background: #1e1e1e;
    border: 1px solid #444;
    border-radius: 6px;
    color: #f1f1f1;
    font-size: 1rem;
    transition: border-color 0.2s ease;
    outline: none;
  } */
  
  /* .inputSearchBar input:focus {
    border-color: #9a6529;
  } */
  
  /* Floating label approach (optional) */
  /* .inputSearchBar span {
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 14px;
    color: #999;
    transition: 0.2s ease;
    font-size: 1rem;
  } */
  
  /* .inputSearchBar input:valid ~ span,
  .inputSearchBar input:focus ~ span,
  .inputSearchBar input:not(:placeholder-shown) ~ span {
    transform: translateY(-22px) scale(0.9);
    color: #9a6529;
  } */
  
  /* The thin bar under the input (optional) */
  /* .inputSearchBar i {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 2px;
    background: #9a6529;
    transition: width 0.3s ease;
  }
  .inputSearchBar input:focus ~ i {
    width: 100%;
  } */
  
  /* Save/Exit Buttons inside the filter bar */
  .saveDeckButton {
    margin-left: auto; /* push it to the right if you want */
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .deckWarningText {
    display: block;
    margin-top: 4px;
    font-size: 0.85rem;
    color: #ffd1d1; /* Light caution color */
  }
  
  /* ───────────────── RESULTS & PAGINATION ───────────────── */
  .cardList-results {
    /* Container for <CardPages> output */
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 20px;
    min-height: 400px; /* or whatever is needed */
  }
  
  /* Pagination controls */
  .cardList-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .pagination-pageNumber {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  /* 
    The .biggerButton style can match your existing store CTA:
    If you have .biggerButton in another file, 
    consider removing or customizing it here. 
  */
  
  .biggerButton:hover {
    background-color: #7e5224;
  }

  .cardListFooter {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  
  /* Responsive tweaks */
  @media (max-width: 768px) {
    .cardListOptionsBox {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .inputSearchBar {
      width: 100%;
    }
  }